import { UnfoldMore as UnfoldMoreIcon, UnfoldLess as UnfoldLessIcon } from '@mui/icons-material';
import {
  Button,
  InputAdornment,
  TextField,
  Typography,
  Paper,
  Box,
  Autocomplete,
  Grid,
  CardContent,
  Stack,
  Collapse,
  useMediaQuery,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { uniqBy } from 'lodash';
import React, { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import ProjectModel from '@/entities/project/ProjectModel';
import UserEntity from '@/entities/user';
import UserModel from '@/entities/user/UserModel';
import { LoggedHourHo } from '@/graphql';
import { dateOnly, dateToHHMM, HHMMToMinutes, minutesToHHMM } from '@/lib/formatTime';
import { isAppleDevice } from '@/lib/misc';
import { LogHoursStore, useLogHoursStore } from '@/stores/LogHoursStore';
import { hasPermission, useAuthenticatedUserStore } from '@/stores/UserStore';

import useStyles from './LogHoursStyles';
import { minutesToHoursAndMinutes } from '@/lib/util';

export type OptionType = {
  id: string;
  label: string;
};

export type ProjectOptionType = OptionType & {
  active: boolean;
};

export interface LoggedHoursFormData {
  project: ProjectOptionType | null;
  category: OptionType | null;
  user: OptionType | null;
  description: string;
  date: Date;
  start: string;
  end: string;
  declarableTime?: number | null;
  declarableTimeHours?: number | null;
  declarableTimeMinutes?: number | null;
  break: string;
  duration: string;
  tenant: OptionType | null;
}
type LoggedHoursFormInputs = Omit<LoggedHoursFormData, 'duration' | 'date'> & { date?: Date };

const defaultLoggedHoursFormInputs: Omit<LoggedHoursFormInputs, 'date'> = {
  project: null,
  category: null,
  tenant: null,
  user: null,
  description: '',
  start: '',
  end: '',
  declarableTime: null,
  break: '00:00',
};

export type LoggedHourSubmitAction = 'create' | 'edit';

export type LoggedHoursFormProps = {
  loggedHour?: LoggedHourHo;
  handleSubmit: (data: LoggedHoursFormData, callback?: (success: boolean) => void) => void;
  event?: React.FormEvent<HTMLFormElement>;
  preFilledOptions?: { project: ProjectOptionType; tenant: OptionType | null };
  fetchUsers?: boolean;
  showDateField?: boolean;

  /**
   * Use the tablet variant of the form.
   *
   * default: `true`
   */
  spread?: boolean;
  /**
   * Determines whether just hours can be inputted or from startTime till endTime.
   *
   * Default view is startTime till endTime
   *
   * @default false
   */
  addendum?: boolean;
};

export function LoggedHoursForm({
  loggedHour,
  handleSubmit,
  preFilledOptions,
  fetchUsers,
  showDateField = false,
  addendum = false,
}: LoggedHoursFormProps) {
  const { date } = useLogHoursStore();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);
  const user = useAuthenticatedUserStore();

  const { data: usersWithCategories, loading: loadingUserWithCategories } = UserModel.useGetAll({
    variables: {
      ...(!fetchUsers ? { filter: { id: { eq: user.id } } } : {}),
      withCategories: true,
    },
  });

  const { projects: projectsWithCategories = [], loading: loadingProjectsWithCategories } = ProjectModel.useGetProjectsWithCategoriesHasHours();

  const edit = useMemo(() => !!loggedHour, [loggedHour]);
  const tenantOptions = useMemo(() => {
    const tenants = !hasPermission('loggedHour:create', user)
      ? user.tenants
      : projectsWithCategories.flatMap((project) => project.tenants.items ?? []);

    return (
      uniqBy(tenants, (tenant) => tenant.id).map((tenant) => ({
        id: tenant.id,
        label: tenant.name,
      })) ?? []
    );
  }, [projectsWithCategories, user]);

  const [formInputs, setFormInputs] = useState<LoggedHoursFormInputs>(() => {
    if (edit) {
      const { hours, minutes } = minutesToHoursAndMinutes(loggedHour?.declarableTime || 0);

      return {
        description: loggedHour?.description || '',
        start: dateToHHMM(loggedHour?.startDatetime, { leadingZero: true }),
        end: dateToHHMM(loggedHour?.endDatetime, { leadingZero: true }),
        declarableTime: loggedHour?.declarableTime ? loggedHour.declarableTime / 60 : null,
        declarableTimeHours: hours,
        declarableTimeMinutes: minutes,
        break: minutesToHHMM(loggedHour?.break || 0),
        date: new Date(dateOnly(loggedHour?.startDatetime)),
        project: loggedHour?.project ? { id: loggedHour.project.id, label: loggedHour.project.name, active: loggedHour.project.active } : null,
        category: loggedHour?.category ? { id: loggedHour?.category.id, label: loggedHour?.category.name } : null,
        tenant: loggedHour?.tenant ? { id: loggedHour.tenant.id, label: loggedHour.tenant.name } : null,
        user: loggedHour?.user,
      };
    }

    let tenantOption = tenantOptions.length === 1 ? tenantOptions[0] : null;
    const projectOption = preFilledOptions?.project ?? null;

    if (preFilledOptions?.tenant) {
      tenantOption = preFilledOptions.tenant;
    }

    const defaultValues: LoggedHoursFormInputs = {
      ...defaultLoggedHoursFormInputs,
      user: { id: user.id, label: `${user.name} ${user.lastName ?? ''}` },
      tenant: tenantOption,
      project: projectOption,
      start: dateToHHMM(undefined, { leadingZero: true }),
      end: dateToHHMM(undefined, { leadingZero: true }),
    };

    if (showDateField) {
      defaultValues.date = new Date(dateOnly(date));
    }

    return defaultValues;
  });

  const userFilters = useMemo(() => {
    let filter = {};

    if (formInputs.project) {
      filter = { userTenantRoles: { project: { id: { eq: formInputs.project.id } } } };
    }

    return filter;
  }, [formInputs]);

  const { data: usersData, loading: loadingUsers } = UserEntity.model.useGetAll({
    skip: !fetchUsers,
    variables: { filter: userFilters },
  });

  const projectOptions = useMemo(() => {
    const shouldFilterRoles =
      !!user.projectIds && !hasPermission('loggedHour:create', user) && !hasPermission('loggedHour:create-project', user);

    const filteredProjects = !shouldFilterRoles
      ? projectsWithCategories
      : projectsWithCategories.filter((project) => user.projectIds?.has(project.id));

    return (
      formInputs.tenant?.id
        ? filteredProjects.filter((project) => project.tenants.items?.find((tenant) => tenant.id === formInputs.tenant?.id))
        : filteredProjects
    ).map((project) => {
      return {
        id: project.id,
        active: project.active,
        label: [project?.customer?.name, `[${project.name}]`].filter(Boolean).join(' '),
      };
    });
  }, [formInputs.tenant, projectsWithCategories, user]);

  const duration = useMemo(() => {
    const startHHMMToMinutes = HHMMToMinutes(formInputs.start);
    const startMinutes = startHHMMToMinutes || HHMMToMinutes(dateToHHMM());

    const endHHMMToMinutes = HHMMToMinutes(formInputs.end);
    const endMinutes = endHHMMToMinutes || HHMMToMinutes(dateToHHMM());

    let endStartDiff = endMinutes - startMinutes;

    if (endStartDiff < 0) {
      endStartDiff += 24 * 60;
    }

    const _break = HHMMToMinutes(formInputs.break);

    const calculated = endStartDiff - _break;

    return minutesToHHMM(calculated < 0 ? 0 : calculated);
  }, [formInputs.break, formInputs.end, formInputs.start]);

  const handleInputChange = useCallback((input: Partial<LoggedHoursFormInputs>) => {
    setFormInputs((prev) => ({ ...prev, ...input }));
  }, []);

  const userOptions = useMemo(() => {
    if (!loadingUsers && usersData?.users?.items) {
      return usersData.users?.items?.map((userItem) => ({ id: userItem.id, label: `${userItem.name} ${userItem.lastName}` }));
    }

    const currUser = { id: user.id, label: `${user.name} ${user.lastName ?? ''}` };

    handleInputChange({ user: currUser });

    return [currUser];
  }, [usersData, loadingUsers, user, handleInputChange]);

  const categoryOptions = useMemo<OptionType[]>(() => {
    const projectWithCategories = projectsWithCategories.find(({ id }) => id === formInputs.project?.id);
    if (!projectWithCategories) {
      return [];
    }

    const userWithCategories = usersWithCategories?.users.items?.find((entry) => entry.id === (formInputs.user?.id || user.id));
    const userAssignedCategoryIds = userWithCategories?.categories?.items?.map((category) => category.id) ?? [];
    const categoriesPartOfProject = projectWithCategories.categories.items.some((category) => userAssignedCategoryIds.includes(category.id));

    let filteredCategories = projectWithCategories.categories.items;

    if (categoriesPartOfProject && userAssignedCategoryIds.length > 0) {
      filteredCategories = projectWithCategories.categories.items.filter((category) => userAssignedCategoryIds.includes(category.id));
    }

    return filteredCategories
      .sort((a, b) => {
        // If both items have parent categories, compare their parent order
        if (a.parentCategory && b.parentCategory) {
          return a.parentCategory.order - b.parentCategory.order;
        }

        // If only one item has a parent category, move it after its parent
        if (a.parentCategory) {
          return a.parentCategory.order - b.order;
        }

        if (b.parentCategory) {
          return a.order - b.parentCategory.order;
        }

        // If neither item has a parent category, compare their order
        return a.order - b.order;
      })
      .map(({ id, name, parentCategory }) => ({
        id,
        label: name,
        parentName: parentCategory?.name || t('entities:categories'),
      }));
  }, [formInputs.project?.id, formInputs.user?.id, projectsWithCategories, user.id, usersWithCategories?.users.items, t]);

  useEffect(() => {
    /**
     * Upon changing the tenant or project, check if the selected child entries are part of the newly selected item.
     * If it is, keep it selected.
     * Otherwise, set it to `null`.
     */
    if (!loadingProjectsWithCategories && formInputs.project && !projectOptions.find(({ id }) => id === formInputs.project?.id)) {
      handleInputChange({ project: null });
    }

    if (formInputs.category && !categoryOptions.find(({ id }) => id === formInputs.category?.id)) {
      handleInputChange({ category: null });
    }
  }, [categoryOptions, formInputs.category, formInputs.project, handleInputChange, projectOptions, loadingProjectsWithCategories]);

  useEffect(() => {
    setFormInputs((prev) => ({
      ...prev,
      tenant: prev.tenant || (tenantOptions.length === 1 ? tenantOptions[0] : null),
    }));
  }, [tenantOptions]);

  useEffect(() => {
    setFormInputs((prev) => ({
      ...prev,
      project: prev.project || (projectOptions.length === 1 ? projectOptions[0] : null),
    }));
  }, [projectOptions]);

  useEffect(() => {
    setFormInputs((prev) => ({
      ...prev,
      category: prev.category || (categoryOptions.length === 1 ? categoryOptions[0] : null),
    }));
  }, [categoryOptions]);

  const getSubmitData = () =>
    edit || showDateField ? ({ ...formInputs, duration } as LoggedHoursFormData) : { ...formInputs, date, duration };

  const handleFormSubmit = () => {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    handleSubmit(getSubmitData(), (success) => {
      setSubmitting(false);

      if (!success || edit) {
        return;
      }

      setFormInputs((prevFormInputs) => ({
        ...defaultLoggedHoursFormInputs,
        start: prevFormInputs.end,
        end: prevFormInputs.end,
        declarableTime: null,
        declarableTimeHours: null,
        declarableTimeMinutes: null,
        tenant: tenantOptions.length === 1 ? tenantOptions[0] : null,
        project: projectOptions.length === 1 ? projectOptions[0] : null,
        category: categoryOptions.length === 1 ? categoryOptions[0] : null,
      }));
    });
  };

  const handleKeyEvent = (event: KeyboardEvent<HTMLDivElement>) => {
    if (submitting || event.code !== 'Enter') {
      return;
    }

    if (isAppleDevice() && !event.metaKey) {
      return;
    }

    if (!event.ctrlKey) {
      return;
    }

    const submitData = getSubmitData();

    // Check if the required fields are set
    if (!submitData.project || !submitData.category || !submitData.description || !submitData.duration || submitData.duration === '00:00') {
      return;
    }

    handleFormSubmit();
  };

  return (
    <Stack spacing={2} onKeyDown={handleKeyEvent}>
      {tenantOptions.length !== 1 && (
        <Autocomplete
          fullWidth
          disablePortal
          options={tenantOptions}
          value={formInputs.tenant}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_, value) => handleInputChange({ tenant: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: !!formInputs.tenant,
                className: formInputs.tenant ? undefined : classes.autocompleteLabel,
              }}
              variant="outlined"
              label={t('logHours:tenant_placeholder')}
            />
          )}
          autoHighlight
          autoSelect
          disableClearable
          classes={{
            input: classes.autocompleteInput,
            root: classes.autocompleteRoot,
          }}
        />
      )}

      <Autocomplete
        fullWidth
        disablePortal
        options={projectOptions}
        groupBy={(option) => (option.active ? t('logHours:active') : t('logHours:inactive'))}
        value={formInputs.project} // This has to be null to be unset, currently removed undefined to hotfix this.
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_, value) => handleInputChange({ project: value })}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: !!formInputs.project,
              className: formInputs.project ? undefined : classes.autocompleteLabel,
            }}
            variant="outlined"
            label={t('logHours:project_placeholder')}
          />
        )}
        autoHighlight
        autoSelect
        disableClearable
        disabled={loadingProjectsWithCategories || loadingUserWithCategories || projectOptions.length <= 1}
        classes={{
          input: classes.autocompleteInput,
          root: classes.autocompleteRoot,
        }}
      />

      {fetchUsers && userOptions.length > 1 && (
        <Autocomplete
          fullWidth
          disablePortal
          options={userOptions}
          value={formInputs.user}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_, value) => handleInputChange({ user: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: !!formInputs.user,
                className: formInputs.user ? undefined : classes.autocompleteLabel,
              }}
              variant="outlined"
              label={t('logHours:user_placeholder')}
            />
          )}
          autoHighlight
          autoSelect
          disableClearable
          classes={{
            input: classes.autocompleteInput,
            root: classes.autocompleteRoot,
          }}
        />
      )}

      {categoryOptions.length !== 1 && (
        <Autocomplete
          fullWidth
          disablePortal
          options={categoryOptions}
          value={formInputs.category}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          groupBy={(option) => option.parentName}
          onChange={(_, value) => handleInputChange({ category: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: !!formInputs.category,
                className: formInputs.category ? undefined : classes.autocompleteLabel,
              }}
              variant="outlined"
              label={t('logHours:category_placeholder')}
            />
          )}
          autoHighlight
          autoSelect
          disableClearable
          // groupBy, renderGroup
          disabled={loadingProjectsWithCategories || loadingUserWithCategories}
          classes={{
            input: classes.autocompleteInput,
            root: classes.autocompleteRoot,
          }}
        />
      )}

      <TextField
        fullWidth
        label={t('logHours:description')}
        variant="outlined"
        multiline
        minRows={2}
        maxRows={5}
        value={formInputs.description}
        onChange={(e) => handleInputChange({ description: e.target.value })}
        InputProps={{
          classes: {
            multiline: classes.noPadding,
            input: classes.input,
          },
        }}
      />

      {(showDateField || edit) && (
        <DatePicker
          renderInput={(props) => <TextField {...props} InputProps={{ classes: { input: classes.input } }} variant="outlined" />}
          value={formInputs.date}
          onChange={(_date) => {
            handleInputChange({ date: _date === null ? undefined : _date });

            if (_date !== null) {
              // Update the date in the store so we use the same date for a next insertion.
              LogHoursStore.set('date', _date);
            }
          }}
        />
      )}

      <Grid container gap={1}>
        <Grid item flex={1}>
          {addendum && (
            <Box display="flex" gap={1}>
              <TextField
                value={formInputs.declarableTimeHours}
                onChange={(e) => handleInputChange({ declarableTimeHours: Number(e.target.value) })}
                fullWidth
                type="number"
                label={t('logHours:hours')}
                variant="outlined"
                InputProps={{ classes: { input: classes.input } }}
              />
              <TextField
                value={formInputs.declarableTimeMinutes}
                onChange={(e) => handleInputChange({ declarableTimeMinutes: Number(e.target.value) })}
                fullWidth
                type="number"
                label={t('logHours:minutes')}
                variant="outlined"
                InputProps={{ classes: { input: classes.input } }}
              />
            </Box>
          )}

          {!addendum && (
            <InputMask
              mask="29:59"
              formatChars={{
                2: '[0-2]',
                5: '[0-5]',
                9: '[0-9]',
              }}
              alwaysShowMask
              value={formInputs.start}
              onChange={(e) => handleInputChange({ start: e.target.value })}
            >
              {(inputProps: any) => (
                <TextField
                  {...inputProps}
                  fullWidth
                  label={t('logHours:start_time')}
                  variant="outlined"
                  InputProps={{ classes: { input: classes.input } }}
                />
              )}
            </InputMask>
          )}
        </Grid>

        {!addendum && (
          <Grid item flex={1}>
            <InputMask
              mask="29:59"
              formatChars={{
                2: '[0-2]',
                5: '[0-5]',
                9: '[0-9]',
              }}
              alwaysShowMask
              value={formInputs.end}
              onChange={(e) => handleInputChange({ end: e.target.value })}
            >
              {(inputProps: any) => (
                <TextField
                  {...inputProps}
                  fullWidth
                  label={t('logHours:end_time')}
                  variant="outlined"
                  InputProps={{
                    classes: { input: classes.input },
                    endAdornment: (
                      <InputAdornment position="end" style={{ marginRight: '0.6rem', marginLeft: 0, position: 'absolute', right: 0 }}>
                        <a
                          onClick={() => handleInputChange({ end: dateToHHMM(undefined, { leadingZero: true }) })}
                          className={classes.nowButton}
                        >
                          {t('logHours:now')}
                        </a>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </InputMask>
          </Grid>
        )}

        {!addendum && (
          <Grid item flex={1}>
            <InputMask
              mask="29:59"
              formatChars={{
                2: '[0-2]',
                5: '[0-5]',
                9: '[0-9]',
              }}
              alwaysShowMask
              value={formInputs.break}
              onChange={(e) => handleInputChange({ break: e.target.value })}
            >
              {(inputProps: any) => (
                <TextField
                  {...inputProps}
                  fullWidth
                  label={t('logHours:break')}
                  variant="outlined"
                  InputProps={{ classes: { input: classes.input } }}
                />
              )}
            </InputMask>
          </Grid>
        )}
      </Grid>

      <Grid container gap={1} justifyContent="space-between" alignItems="center">
        <Grid item>{!addendum && <Typography variant="h3">{duration}</Typography>}</Grid>
        <Grid item>
          <Button variant="contained" color="primary" className={classes.logButton} onClick={handleFormSubmit} disabled={submitting}>
            {edit ? t('logHours:submit_button.edit') : t('logHours:submit_button.create')}
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
}

function LoggedHoursFormContainer(props: LoggedHoursFormProps) {
  const { loggedHour } = props;

  const { classes, theme } = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const edit = useMemo(() => !!loggedHour, [loggedHour]);

  const handleHeaderClick = () => {
    if (isSmallScreen) {
      setOpen((prev) => !prev);
    }
  };

  return (
    <Paper>
      <Box className={classes.heading} onClick={handleHeaderClick} component={isSmallScreen ? Button : 'div'}>
        <Typography variant="h5">{edit ? t('logHours:heading.edit') : t('logHours:heading.create')}</Typography>
        {open ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
      </Box>

      <Collapse in={!isSmallScreen || open}>
        <CardContent>
          <LoggedHoursForm {...props} addendum={loggedHour?.addendum} />
        </CardContent>
      </Collapse>
    </Paper>
  );
}

export default LoggedHoursFormContainer;
