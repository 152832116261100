import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        name: {
          type: String,
          form: true,
          view: true,
        },
        email: {
          type: String,
          form: true,
          view: true,
        },
        emailVerifiedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        active: {
          type: Boolean,
          defaultValue: true,
          form: {
            component: 'Boolean',
          },
          view: true,
        },
        password: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        confirmationCode: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        avatar: {
          type: String,
          optional: true,
          form: {
            component: 'Upload',
          },
          view: true,
        },
        totpSecret: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        totpVerified: {
          type: Boolean,
          optional: true,
          form: false,
          view: true,
        },
        totpBackupCodes: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        termsAndConditions: {
          type: Boolean,
          optional: true,
          form: {
            component: 'Boolean',
          },
          view: true,
        },
        locale: {
          type: String,
          form: {
            component: 'Select',
          },
          view: true,
          allowedValues: [
            'en-US',
            'nl-NL',
          ],
        },
        timeZone: {
          type: String,
          form: {
            component: 'Select',
          },
          view: true,
          allowedValues: [
            'Africa/Abidjan',
            'Africa/Accra',
            'Africa/Addis_Ababa',
            'Africa/Algiers',
            'Africa/Asmera',
            'Africa/Bamako',
            'Africa/Bangui',
            'Africa/Banjul',
            'Africa/Bissau',
            'Africa/Blantyre',
            'Africa/Brazzaville',
            'Africa/Bujumbura',
            'Africa/Cairo',
            'Africa/Casablanca',
            'Africa/Ceuta',
            'Africa/Conakry',
            'Africa/Dakar',
            'Africa/Dar_es_Salaam',
            'Africa/Djibouti',
            'Africa/Douala',
            'Africa/El_Aaiun',
            'Africa/Freetown',
            'Africa/Gaborone',
            'Africa/Harare',
            'Africa/Johannesburg',
            'Africa/Juba',
            'Africa/Kampala',
            'Africa/Khartoum',
            'Africa/Kigali',
            'Africa/Kinshasa',
            'Africa/Lagos',
            'Africa/Libreville',
            'Africa/Lome',
            'Africa/Luanda',
            'Africa/Lubumbashi',
            'Africa/Lusaka',
            'Africa/Malabo',
            'Africa/Maputo',
            'Africa/Maseru',
            'Africa/Mbabane',
            'Africa/Mogadishu',
            'Africa/Monrovia',
            'Africa/Nairobi',
            'Africa/Ndjamena',
            'Africa/Niamey',
            'Africa/Nouakchott',
            'Africa/Ouagadougou',
            'Africa/Porto-Novo',
            'Africa/Sao_Tome',
            'Africa/Tripoli',
            'Africa/Tunis',
            'Africa/Windhoek',
            'America/Adak',
            'America/Anchorage',
            'America/Anguilla',
            'America/Antigua',
            'America/Araguaina',
            'America/Argentina/La_Rioja',
            'America/Argentina/Rio_Gallegos',
            'America/Argentina/Salta',
            'America/Argentina/San_Juan',
            'America/Argentina/San_Luis',
            'America/Argentina/Tucuman',
            'America/Argentina/Ushuaia',
            'America/Aruba',
            'America/Asuncion',
            'America/Bahia',
            'America/Bahia_Banderas',
            'America/Barbados',
            'America/Belem',
            'America/Belize',
            'America/Blanc-Sablon',
            'America/Boa_Vista',
            'America/Bogota',
            'America/Boise',
            'America/Buenos_Aires',
            'America/Cambridge_Bay',
            'America/Campo_Grande',
            'America/Cancun',
            'America/Caracas',
            'America/Catamarca',
            'America/Cayenne',
            'America/Cayman',
            'America/Chicago',
            'America/Chihuahua',
            'America/Ciudad_Juarez',
            'America/Coral_Harbour',
            'America/Cordoba',
            'America/Costa_Rica',
            'America/Creston',
            'America/Cuiaba',
            'America/Curacao',
            'America/Danmarkshavn',
            'America/Dawson',
            'America/Dawson_Creek',
            'America/Denver',
            'America/Detroit',
            'America/Dominica',
            'America/Edmonton',
            'America/Eirunepe',
            'America/El_Salvador',
            'America/Fort_Nelson',
            'America/Fortaleza',
            'America/Glace_Bay',
            'America/Godthab',
            'America/Goose_Bay',
            'America/Grand_Turk',
            'America/Grenada',
            'America/Guadeloupe',
            'America/Guatemala',
            'America/Guayaquil',
            'America/Guyana',
            'America/Halifax',
            'America/Havana',
            'America/Hermosillo',
            'America/Indiana/Knox',
            'America/Indiana/Marengo',
            'America/Indiana/Petersburg',
            'America/Indiana/Tell_City',
            'America/Indiana/Vevay',
            'America/Indiana/Vincennes',
            'America/Indiana/Winamac',
            'America/Indianapolis',
            'America/Inuvik',
            'America/Iqaluit',
            'America/Jamaica',
            'America/Jujuy',
            'America/Juneau',
            'America/Kentucky/Monticello',
            'America/Kralendijk',
            'America/La_Paz',
            'America/Lima',
            'America/Los_Angeles',
            'America/Louisville',
            'America/Lower_Princes',
            'America/Maceio',
            'America/Managua',
            'America/Manaus',
            'America/Marigot',
            'America/Martinique',
            'America/Matamoros',
            'America/Mazatlan',
            'America/Mendoza',
            'America/Menominee',
            'America/Merida',
            'America/Metlakatla',
            'America/Mexico_City',
            'America/Miquelon',
            'America/Moncton',
            'America/Monterrey',
            'America/Montevideo',
            'America/Montserrat',
            'America/Nassau',
            'America/New_York',
            'America/Nome',
            'America/Noronha',
            'America/North_Dakota/Beulah',
            'America/North_Dakota/Center',
            'America/North_Dakota/New_Salem',
            'America/Ojinaga',
            'America/Panama',
            'America/Paramaribo',
            'America/Phoenix',
            'America/Port-au-Prince',
            'America/Port_of_Spain',
            'America/Porto_Velho',
            'America/Puerto_Rico',
            'America/Punta_Arenas',
            'America/Rankin_Inlet',
            'America/Recife',
            'America/Regina',
            'America/Resolute',
            'America/Rio_Branco',
            'America/Santarem',
            'America/Santiago',
            'America/Santo_Domingo',
            'America/Sao_Paulo',
            'America/Scoresbysund',
            'America/Sitka',
            'America/St_Barthelemy',
            'America/St_Johns',
            'America/St_Kitts',
            'America/St_Lucia',
            'America/St_Thomas',
            'America/St_Vincent',
            'America/Swift_Current',
            'America/Tegucigalpa',
            'America/Thule',
            'America/Tijuana',
            'America/Toronto',
            'America/Tortola',
            'America/Vancouver',
            'America/Whitehorse',
            'America/Winnipeg',
            'America/Yakutat',
            'Antarctica/Casey',
            'Antarctica/Davis',
            'Antarctica/DumontDUrville',
            'Antarctica/Macquarie',
            'Antarctica/Mawson',
            'Antarctica/McMurdo',
            'Antarctica/Palmer',
            'Antarctica/Rothera',
            'Antarctica/Syowa',
            'Antarctica/Troll',
            'Antarctica/Vostok',
            'Arctic/Longyearbyen',
            'Asia/Aden',
            'Asia/Almaty',
            'Asia/Amman',
            'Asia/Anadyr',
            'Asia/Aqtau',
            'Asia/Aqtobe',
            'Asia/Ashgabat',
            'Asia/Atyrau',
            'Asia/Baghdad',
            'Asia/Bahrain',
            'Asia/Baku',
            'Asia/Bangkok',
            'Asia/Barnaul',
            'Asia/Beirut',
            'Asia/Bishkek',
            'Asia/Brunei',
            'Asia/Calcutta',
            'Asia/Chita',
            'Asia/Colombo',
            'Asia/Damascus',
            'Asia/Dhaka',
            'Asia/Dili',
            'Asia/Dubai',
            'Asia/Dushanbe',
            'Asia/Famagusta',
            'Asia/Gaza',
            'Asia/Hebron',
            'Asia/Hong_Kong',
            'Asia/Hovd',
            'Asia/Irkutsk',
            'Asia/Jakarta',
            'Asia/Jayapura',
            'Asia/Jerusalem',
            'Asia/Kabul',
            'Asia/Kamchatka',
            'Asia/Karachi',
            'Asia/Katmandu',
            'Asia/Khandyga',
            'Asia/Krasnoyarsk',
            'Asia/Kuala_Lumpur',
            'Asia/Kuching',
            'Asia/Kuwait',
            'Asia/Macau',
            'Asia/Magadan',
            'Asia/Makassar',
            'Asia/Manila',
            'Asia/Muscat',
            'Asia/Nicosia',
            'Asia/Novokuznetsk',
            'Asia/Novosibirsk',
            'Asia/Omsk',
            'Asia/Oral',
            'Asia/Phnom_Penh',
            'Asia/Pontianak',
            'Asia/Pyongyang',
            'Asia/Qatar',
            'Asia/Qostanay',
            'Asia/Qyzylorda',
            'Asia/Rangoon',
            'Asia/Riyadh',
            'Asia/Saigon',
            'Asia/Sakhalin',
            'Asia/Samarkand',
            'Asia/Seoul',
            'Asia/Shanghai',
            'Asia/Singapore',
            'Asia/Srednekolymsk',
            'Asia/Taipei',
            'Asia/Tashkent',
            'Asia/Tbilisi',
            'Asia/Tehran',
            'Asia/Thimphu',
            'Asia/Tokyo',
            'Asia/Tomsk',
            'Asia/Ulaanbaatar',
            'Asia/Urumqi',
            'Asia/Ust-Nera',
            'Asia/Vientiane',
            'Asia/Vladivostok',
            'Asia/Yakutsk',
            'Asia/Yekaterinburg',
            'Asia/Yerevan',
            'Atlantic/Azores',
            'Atlantic/Bermuda',
            'Atlantic/Canary',
            'Atlantic/Cape_Verde',
            'Atlantic/Faeroe',
            'Atlantic/Madeira',
            'Atlantic/Reykjavik',
            'Atlantic/South_Georgia',
            'Atlantic/St_Helena',
            'Atlantic/Stanley',
            'Australia/Adelaide',
            'Australia/Brisbane',
            'Australia/Broken_Hill',
            'Australia/Darwin',
            'Australia/Eucla',
            'Australia/Hobart',
            'Australia/Lindeman',
            'Australia/Lord_Howe',
            'Australia/Melbourne',
            'Australia/Perth',
            'Australia/Sydney',
            'Europe/Amsterdam',
            'Europe/Andorra',
            'Europe/Astrakhan',
            'Europe/Athens',
            'Europe/Belgrade',
            'Europe/Berlin',
            'Europe/Bratislava',
            'Europe/Brussels',
            'Europe/Bucharest',
            'Europe/Budapest',
            'Europe/Busingen',
            'Europe/Chisinau',
            'Europe/Copenhagen',
            'Europe/Dublin',
            'Europe/Gibraltar',
            'Europe/Guernsey',
            'Europe/Helsinki',
            'Europe/Isle_of_Man',
            'Europe/Istanbul',
            'Europe/Jersey',
            'Europe/Kaliningrad',
            'Europe/Kiev',
            'Europe/Kirov',
            'Europe/Lisbon',
            'Europe/Ljubljana',
            'Europe/London',
            'Europe/Luxembourg',
            'Europe/Madrid',
            'Europe/Malta',
            'Europe/Mariehamn',
            'Europe/Minsk',
            'Europe/Monaco',
            'Europe/Moscow',
            'Europe/Oslo',
            'Europe/Paris',
            'Europe/Podgorica',
            'Europe/Prague',
            'Europe/Riga',
            'Europe/Rome',
            'Europe/Samara',
            'Europe/San_Marino',
            'Europe/Sarajevo',
            'Europe/Saratov',
            'Europe/Simferopol',
            'Europe/Skopje',
            'Europe/Sofia',
            'Europe/Stockholm',
            'Europe/Tallinn',
            'Europe/Tirane',
            'Europe/Ulyanovsk',
            'Europe/Vaduz',
            'Europe/Vatican',
            'Europe/Vienna',
            'Europe/Vilnius',
            'Europe/Volgograd',
            'Europe/Warsaw',
            'Europe/Zagreb',
            'Europe/Zurich',
            'Indian/Antananarivo',
            'Indian/Chagos',
            'Indian/Christmas',
            'Indian/Cocos',
            'Indian/Comoro',
            'Indian/Kerguelen',
            'Indian/Mahe',
            'Indian/Maldives',
            'Indian/Mauritius',
            'Indian/Mayotte',
            'Indian/Reunion',
            'Pacific/Apia',
            'Pacific/Auckland',
            'Pacific/Bougainville',
            'Pacific/Chatham',
            'Pacific/Easter',
            'Pacific/Efate',
            'Pacific/Enderbury',
            'Pacific/Fakaofo',
            'Pacific/Fiji',
            'Pacific/Funafuti',
            'Pacific/Galapagos',
            'Pacific/Gambier',
            'Pacific/Guadalcanal',
            'Pacific/Guam',
            'Pacific/Honolulu',
            'Pacific/Kiritimati',
            'Pacific/Kosrae',
            'Pacific/Kwajalein',
            'Pacific/Majuro',
            'Pacific/Marquesas',
            'Pacific/Midway',
            'Pacific/Nauru',
            'Pacific/Niue',
            'Pacific/Norfolk',
            'Pacific/Noumea',
            'Pacific/Pago_Pago',
            'Pacific/Palau',
            'Pacific/Pitcairn',
            'Pacific/Ponape',
            'Pacific/Port_Moresby',
            'Pacific/Rarotonga',
            'Pacific/Saipan',
            'Pacific/Tahiti',
            'Pacific/Tarawa',
            'Pacific/Tongatapu',
            'Pacific/Truk',
            'Pacific/Wake',
            'Pacific/Wallis',
          ],
        },
        userTenantRoles: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'userTenantRole',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'userTenantRoles.$': {
          type: String,
          optional: true,
        },
        createdAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        updatedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        deletedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        lastName: {
          type: String,
          form: true,
          view: true,
        },
        function: {
          type: String,
          optional: true,
          form: true,
          view: true,
        },
        phoneNumber: {
          type: String,
          optional: true,
          form: true,
          view: true,
        },
        note: {
          type: String,
          optional: true,
          form: true,
          view: true,
        },
        contracts: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'contract',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'contracts.$': {
          type: String,
          optional: true,
        },
        availabilities: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'availability',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'availabilities.$': {
          type: String,
          optional: true,
        },
        daysOffs: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'daysOff',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'daysOffs.$': {
          type: String,
          optional: true,
        },
        loggedHours: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'loggedHour',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'loggedHours.$': {
          type: String,
          optional: true,
        },
        approvedLoggedHours: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'loggedHour',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'approvedLoggedHours.$': {
          type: String,
          optional: true,
        },
        plannedHours: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'plannedHour',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'plannedHours.$': {
          type: String,
          optional: true,
        },
        productivities: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'productivity',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'productivities.$': {
          type: String,
          optional: true,
        },
        responsibleForProjects: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'project',
            displayField: 'name',
          },
          optional: true,
          form: false,
          view: true,
        },
        'responsibleForProjects.$': {
          type: String,
          optional: true,
        },
        controllerForProjects: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'project',
            displayField: 'name',
          },
          optional: true,
          form: false,
          view: true,
        },
        'controllerForProjects.$': {
          type: String,
          optional: true,
        },
        categories: {
          type: Array,
          relation: {
            name: 'HasMany',
            type: 'ManyToMany',
            model: 'category',
            displayField: 'name',
          },
          optional: true,
          form: true,
          view: true,
        },
        'categories.$': {
          type: String,
          optional: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:user.id', 'entityFields:default.id'], '') || undefined,
        name: t(['entityFields:user.name', 'entityFields:default.name'], '') || undefined,
        email: t(['entityFields:user.email', 'entityFields:default.email'], '') || undefined,
        emailVerifiedAt: t(['entityFields:user.email_verified_at', 'entityFields:default.email_verified_at'], '') || undefined,
        active: t(['entityFields:user.active', 'entityFields:default.active'], '') || undefined,
        password: t(['entityFields:user.password', 'entityFields:default.password'], '') || undefined,
        confirmationCode: t(['entityFields:user.confirmation_code', 'entityFields:default.confirmation_code'], '') || undefined,
        avatar: t(['entityFields:user.avatar', 'entityFields:default.avatar'], '') || undefined,
        totpSecret: t(['entityFields:user.totp_secret', 'entityFields:default.totp_secret'], '') || undefined,
        totpVerified: t(['entityFields:user.totp_verified', 'entityFields:default.totp_verified'], '') || undefined,
        totpBackupCodes: t(['entityFields:user.totp_backup_codes', 'entityFields:default.totp_backup_codes'], '') || undefined,
        termsAndConditions: t(['entityFields:user.terms_and_conditions', 'entityFields:default.terms_and_conditions'], '') || undefined,
        locale: t(['entityFields:user.locale', 'entityFields:default.locale'], '') || undefined,
        timeZone: t(['entityFields:user.time_zone', 'entityFields:default.time_zone'], '') || undefined,
        userTenantRoles: t(['entityFields:user.user_tenant_role', 'entityFields:default.user_tenant_role'], '') || undefined,
        createdAt: t(['entityFields:user.created_at', 'entityFields:default.created_at'], '') || undefined,
        updatedAt: t(['entityFields:user.updated_at', 'entityFields:default.updated_at'], '') || undefined,
        deletedAt: t(['entityFields:user.deleted_at', 'entityFields:default.deleted_at'], '') || undefined,
        lastName: t(['entityFields:user.last_name', 'entityFields:default.last_name'], '') || undefined,
        function: t(['entityFields:user.function', 'entityFields:default.function'], '') || undefined,
        phoneNumber: t(['entityFields:user.phone_number', 'entityFields:default.phone_number'], '') || undefined,
        note: t(['entityFields:user.note', 'entityFields:default.note'], '') || undefined,
        contracts: t(['entityFields:user.contract', 'entityFields:default.contract'], '') || undefined,
        availabilities: t(['entityFields:user.availability', 'entityFields:default.availability'], '') || undefined,
        daysOffs: t(['entityFields:user.days_off', 'entityFields:default.days_off'], '') || undefined,
        loggedHours: t(['entityFields:user.logged_hour', 'entityFields:default.logged_hour'], '') || undefined,
        approvedLoggedHours: t(['entityFields:user.approved_logged_hour', 'entityFields:default.approved_logged_hour'], '') || undefined,
        plannedHours: t(['entityFields:user.planned_hour', 'entityFields:default.planned_hour'], '') || undefined,
        productivities: t(['entityFields:user.productivity', 'entityFields:default.productivity'], '') || undefined,
        responsibleForProjects: t(['entityFields:user.responsible_for_project', 'entityFields:default.responsible_for_project'], '') || undefined,
        controllerForProjects: t(['entityFields:user.controller_for_project', 'entityFields:default.controller_for_project'], '') || undefined,
        categories: t(['entityFields:user.category', 'entityFields:default.category'], '') || undefined,
});

export default schema;