import { CalendarMonth, Delete, Edit, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Button, ClickAwayListener, IconButton, Paper, Popper, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { addDays, endOfISOWeek, getDay, startOfISOWeek, subDays } from 'date-fns';
import { map, reduce } from 'lodash';
import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

import formatDate from '@/bootstrap/lib/formatDate';
import TabPanel from '@/components/shared/TabPanel';
import {
  useGetOrganizedLoggedHoursQuery,
  useUpdatedLoggedHourSubscription,
  useDeletedLoggedHourSubscription,
  useCreatedLoggedHourSubscription,
} from '@/graphql';
import { LoggedHour } from '@/graphql';
import { dateOnly, dateToHHMM, minutesToHHMM } from '@/lib/formatTime';
import { getDayOfWeek } from '@/lib/misc';
import { LogHoursStore, useLogHoursStore } from '@/stores/LogHoursStore';

import { useRegisteredHoursDisplayStyles } from './RegisteredHoursDisplayStyles';
import { useAuthenticatedUserStore } from '@/stores/UserStore';

function getDayIndex(date: Date): number {
  const index = getDay(date);

  if (index === 0) {
    return 6;
  }

  return index - 1;
}

export type ProcessedLoggedHoursType = {
  [key: string]: { totalTime: number; loggedHours: LoggedHour[] };
};

type Props = {
  onEdit: (loggedHour: LoggedHour) => void;
  onDelete: (loggedHour: LoggedHour) => void;
};

function RegisteredHoursDisplay({ onEdit: handleEdit, onDelete: handleDelete }: Props) {
  const { classes } = useRegisteredHoursDisplayStyles();
  const { date } = useLogHoursStore();
  const { t } = useTranslation();
  const currentUser = useAuthenticatedUserStore();

  const [tab, setTab] = useState(getDayIndex(new Date()));
  const [weeksLoggedHours, setWeeksLoggedHours] = useState<ProcessedLoggedHoursType | undefined>();

  const calendarRef = useRef(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const { data, refetch } = useGetOrganizedLoggedHoursQuery({
    variables: {
      startDate: startOfISOWeek(new Date()),
      endDate: endOfISOWeek(new Date()),
    },
  });

  const changeDate = useCallback(
    (newDate: Date, options?: { refetch: boolean }) => {
      if (newDate instanceof Date) {
        LogHoursStore.set('date', newDate);
      }

      const { refetch: _refetch = true } = options || {};

      if (_refetch) {
        refetch({
          startDate: startOfISOWeek(newDate),
          endDate: endOfISOWeek(newDate),
        });

        setTab(getDayIndex(newDate));
      }
    },
    [refetch]
  );

  const handleChangeTab = (e: SyntheticEvent, newTab: number) => {
    setTab(newTab);
    changeDate(getDayOfWeek(newTab, date), { refetch: false });
  };

  useEffect(() => {
    const loggedHours = data?.getOrganizedLoggedHours;

    if (!loggedHours) {
      return;
    }

    const weekLoggedHours: ProcessedLoggedHoursType = {};

    for (let i = 0; i < 7; i++) {
      weekLoggedHours[dateOnly(getDayOfWeek(i, date))] = {
        totalTime: 0,
        loggedHours: [],
      };
    }

    loggedHours?.forEach((loggedHour) => {
      const loggedHourDate = dateOnly(loggedHour.startDatetime);

      if (!(loggedHourDate in weekLoggedHours)) {
        return;
      }

      weekLoggedHours[loggedHourDate].loggedHours.push(loggedHour);
      weekLoggedHours[loggedHourDate].totalTime += loggedHour.declarableTime as number;
    });

    setWeeksLoggedHours(weekLoggedHours);
  }, [data?.getOrganizedLoggedHours]);

  useCreatedLoggedHourSubscription({
    shouldResubscribe: true,
    fetchPolicy: 'no-cache',
    variables: {
      filter: { userId: { eq: currentUser.id } },
    },
    onSubscriptionData: () => {
      refetch();
    },
  });

  useUpdatedLoggedHourSubscription({
    shouldResubscribe: true,
    fetchPolicy: 'no-cache',
    variables: {
      filter: { userId: { eq: currentUser.id } },
    },
    onSubscriptionData: () => {
      refetch();
    },
  });

  useDeletedLoggedHourSubscription({
    shouldResubscribe: true,
    fetchPolicy: 'no-cache',
    variables: {
      filter: { userId: { eq: currentUser.id } },
    },
    onSubscriptionData: () => {
      refetch();
    },
  });

  const handlePrevWeek = useCallback(() => {
    changeDate(subDays(LogHoursStore.get('date') as Date, 7));
  }, [changeDate]);

  const handleNextWeek = useCallback(() => {
    changeDate(addDays(LogHoursStore.get('date') as Date, 7));
  }, [changeDate]);

  return (
    <Box className={classes.main}>
      <Box className={classes.headerContainer}>
        <Typography variant="h4" color="text.primary" className={classes.title}>{`${formatDate(date, 'do')} ${formatDate(
          date,
          'LLL'
        )} week ${formatDate(date, 'w')}`}</Typography>
        <Box className={classes.actionsContainer} sx={{}}>
          <IconButton onClick={() => setOpenDatePicker((prev) => !prev)} ref={calendarRef}>
            <CalendarMonth />
          </IconButton>
          {openDatePicker && (
            <ClickAwayListener onClickAway={() => setOpenDatePicker(false)}>
              <Popper open={openDatePicker} anchorEl={calendarRef.current}>
                <Paper className={classes.dateRangePickerContainer}>
                  <DayPicker
                    mode="single"
                    month={date}
                    onMonthChange={(newDate) => changeDate(newDate || new Date())}
                    selected={date}
                    onSelect={(newDate) => changeDate(newDate || new Date())}
                    weekStartsOn={1}
                  />
                </Paper>
              </Popper>
            </ClickAwayListener>
          )}

          <IconButton onClick={handlePrevWeek}>
            <KeyboardArrowLeft />
          </IconButton>
          <Button className={classes.actionsContainerToday} onClick={() => changeDate(new Date())}>
            {t('hoursOverview:today')}
          </Button>
          <IconButton onClick={handleNextWeek}>
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      </Box>

      <Box className={classes.registeredHoursDisplayContainer}>
        <Box className={classes.tabsRow}>
          <Tabs scrollButtons="auto" value={tab} onChange={handleChangeTab} variant="scrollable">
            {map(weeksLoggedHours, ({ totalTime }, _date) => (
              <Tab
                key={`tab-${_date}`}
                label={
                  <span>
                    {formatDate(new Date(_date), 'ccc')}
                    <br />
                    {minutesToHHMM(totalTime)}
                  </span>
                }
              />
            ))}
          </Tabs>
          <Typography variant="h5" className={classes.totalHoursOfWeekText}>
            {minutesToHHMM(reduce(weeksLoggedHours, (acc, { totalTime }) => acc + totalTime, 0))}
          </Typography>
        </Box>

        <Box className={classes.registeredHoursPanelsContainer}>
          {map(weeksLoggedHours, ({ loggedHours }, _date) => (
            <TabPanel index={getDayIndex(new Date(_date))} value={tab} key={`panel-${_date}`}>
              {map(loggedHours, (loggedHour) => (
                <Box className={classes.registeredHourRowContainer} key={loggedHour.id}>
                  <Box className={classes.registeredHourInfo}>
                    <Box sx={{ mb: '3px' }}>
                      <Typography variant="body1" sx={{ fontWeight: '500' }}>
                        {!!loggedHour.project.customer && <>{loggedHour.project.customer.name}&nbsp;</>}
                        {`[ ${loggedHour.project.name} ]`}
                      </Typography>
                    </Box>

                    <Box sx={{ mb: '5px' }}>
                      <Typography variant="body2">
                        {loggedHour.category ? loggedHour.category.name : t('hoursOverview:categoryDoesNotExistAnymore')}
                      </Typography>
                    </Box>

                    {!!loggedHour.description && (
                      <Box>
                        <Typography
                          variant="caption"
                          sx={{ color: 'darkgray', lineHeight: 1 }}
                          className={classes.registeredHourInfoDescription}
                        >
                          {loggedHour.description}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box className={classes.registeredHourTimeContainer}>
                    <Typography sx={{ textAlign: 'right' }} variant="h6">
                      {minutesToHHMM(loggedHour.declarableTime as number)}
                    </Typography>

                    <Typography sx={{ textAlign: 'right' }} variant="body2">
                      {`${dateToHHMM(loggedHour.startDatetime)}-${dateToHHMM(loggedHour.endDatetime)}`}
                      <i>{`${loggedHour.break ? ` (${minutesToHHMM(loggedHour.break)})` : ''}`}</i>
                    </Typography>
                  </Box>
                  <Box className={classes.registeredHourActionsContainer}>
                    <IconButton disabled={!loggedHour.project.active} onClick={() => handleEdit?.(loggedHour)}>
                      <Edit />
                    </IconButton>

                    <IconButton disabled={!loggedHour.project.active} onClick={() => handleDelete?.(loggedHour)}>
                      <Delete />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </TabPanel>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default RegisteredHoursDisplay;
